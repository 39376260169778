body {
    font-family: "Arbutus Slab", sans-serif;
    text-align: center;
    color: #ffffff;
    background-color: black;
    margin: 0;
    padding: 0px 10px;
}

h2 {
    margin: 10px auto;
}

p {
    margin: 10px auto;
}

.gallery-header {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.filter-button {
    display: inline-block;
    margin-left: 10px;
    background-color: #e5e5e5;
    color: black;
    padding: 4px 6px;
    cursor: pointer;
}

.filters {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

select {
    font-size: 14px;
}

select option {
    font-size: 14px;
}

.studio {
    width: 80%;
    max-width: 550px;
    height: auto;
    margin-bottom: 10px;
}

.home {
    text-align: center;
    overflow: hidden;
}

.gallery {
    width: calc(100% - 40px);
    margin: 15px auto;
}

.gallery .pswp-thumbnails {
    gap: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.pswp-thumbnail {
    display: block;
    text-align: center;
    height: 250px;
    width: 250px;
    line-height: 250px;
    border: 1px solid #303030;
    padding: 10px;
    position: relative;
}

.pswp-thumbnail:hover {
    border: 1px solid #bbbbbb;
    cursor: pointer;
}

a {
    color: white;
}

a:visited {
    color: white;
}

.pswp-thumbnail img {
    max-width: 250px;
    max-height: 250px;
    display: inline;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

footer {
    margin-top: 30px;
}

.pswp__caption__center {
    text-align: center;
}
